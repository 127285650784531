@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: black;
  background-color: #fafbfb;
  font-family: "DM Sans", sans-serif;
}

@media (min-width: 1200px) {
  body {
    height: 100vh;
    overflow: hidden;
  }
}
